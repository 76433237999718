import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import "tailwindcss/tailwind.css";
import AgencyList from "../agencies/agencyList";
import CreateStore from "../stores/CreateStore";
import StoreComponent from "../stores/StoreList";
import UserList from "../users/UserList";
import UserProfile from "../userprofile/UserProfile";
import Loader from "../loader/Loader";

function AdminDashboard() {
  const [activeTab, setActiveTab] = useState("list");
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAdmin(decodedToken.isAdmin);
      } catch (error) {
        console.error("Failed to decode JWT token", error);
      }
    }
  }, []);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem("token");
      navigate("/");
    }, 2000);
  };

  const updateUserApiCall = async (updatedUser) => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };
  const handleUpdateUser = async (updatedUser) => {
    setLoading(true);
    try {
      await updateUserApiCall(updatedUser);
      setActiveTab("user management");
    } catch (error) {
      console.error("Failed to update user", error);
    } finally {
      setLoading(false);
    }
  };

  const updateAgencyApiCall = async (updatedAgency) => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };
  const handleUpdateAgency = async (updatedAgency) => {
    setLoading(true);
    try {
      await updateAgencyApiCall(updatedAgency);
      setActiveTab("list");
    } catch (error) {
      console.error("Failed to update agency", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (activeTab === "list" || "store") {
  //     setShowLoader(true);
  //     const timer = setTimeout(() => {
  //       setShowLoader(false);
  //     }, 2000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [activeTab]);

  return (
    <div className="min-h-screen flex">
      {loading && <Loader />}
      {/* Left side navigation */}
      <div className="w-1/8 bg-gray-800 text-white p-4">
        <h2 className="text-2xl text-orange-500 font-bold mb-4">XENI</h2>
        <ul className="space-y-4">
          <li>
            <button
              onClick={() => setActiveTab("list")}
              className={`w-full text-left p-2 rounded hover:bg-white hover:text-black ${
                activeTab === "list" ? "bg-orange-500" : ""
              }`}
            >
              Agency
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("store")}
              className={`w-full text-left p-2 rounded hover:bg-white hover:text-black ${
                activeTab === "store" ? "bg-orange-500" : ""
              }`}
            >
              Store
            </button>
          </li>
          {isAdmin && (
            <li>
              <button
                onClick={() => setActiveTab("user management")}
                className={`w-full text-left p-2 rounded hover:bg-white hover:text-black ${
                  activeTab === "user management" ? "bg-orange-500" : ""
                }`}
              >
                User Management
              </button>
            </li>
          )}
          <li>
            <button
              onClick={() => setActiveTab("user profile")}
              className="w-full text-left p-2 rounded hover:bg-white hover:text-black"
            >
              User Profile
            </button>
          </li>
          <li>
            <button
              onClick={handleLogout}
              className="w-full text-left p-2 rounded text-red-500 hover:bg-red-500 hover:text-white"
            >
              Logout
            </button>
          </li>
        </ul>
      </div>

      {/* Right side content */}
      <div className="w-full p-8">
        {showLoader ? <Loader /> : null}
        {activeTab === "list" && (
          <AgencyList onUpdateAgency={handleUpdateAgency} />
        )}
        {!showLoader && activeTab === "store" && <StoreComponent />}
        {activeTab === "createStore" && (
          <CreateStore setActiveTab={setActiveTab} />
        )}
        {activeTab === "user management" && (
          <UserList onUpdateUser={handleUpdateUser} />
        )}
        {activeTab === "user profile" && <UserProfile />}
      </div>
    </div>
  );
}

export default AdminDashboard;
