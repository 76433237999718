import { React, useState } from "react";
import { FaCopy} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import apiService from "../../services/apiService";
import Loader from "../loader/Loader";
import { XCircleIcon } from "@heroicons/react/solid";

const AddCertificateModal = ({ onClose, agency }) => {
  const [error, setError] = useState("");
  const [dnsData, setDnsData] = useState(null);
  const [showDnsPopup, setShowDnsPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [agencyData, setAgency] = useState(null);
  // const [showDnsPopup, setShowDnsPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setShowLoader(true)
    try {
      const response = await apiService.post(
        "/api/certificates/AddCertificate",
        { agencyID: parseInt(agency.ID), storeID: parseInt(agency.fkAssignedStore) },
      );
      setDnsData(response.data); // Assuming the response contains the DNS data
      setAgency(agency); // Assuming the response contains the agency data
      setTimeout(() => {
        setShowLoader(false);
        setShowDnsPopup(true); // Show the DNS popup
      }, 2000);
    } catch (err) {
      console.error("Failed to request certificate", err);
      setError("Failed to request certificate. Please try again.");
      setShowLoader(false);
    }
  };
  const handleDnsPopupClose = () => {
    setShowDnsPopup(false);
    onClose();
  };
  return (
    <div className="fixed inset-0 z-100 overflow-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="text-xl font-bold mb-4">Add Certificate</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="agencyID"
              className="block text-sm font-medium text-gray-700"
            >
              Agency Name
            </label>
            <input
              type="text"
              id="agencyID"
              value={agency.agencyName}
              readOnly
              className="mt-1 block w-full border border-gray-300 rounded p-2 bg-gray-100"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="storeID"
              className="block text-sm font-medium text-gray-700"
            >
              Agency Domain Name
            </label>
            <input
              type="text"
              id="storeID"
              value={agency.agencyDomainName}
              readOnly
              className="mt-1 block w-full border border-gray-300 rounded p-2 bg-gray-100"
            />
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 px-4 py-2 bg-red-300 text-white rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {showLoader && <Loader/>}
      {showDnsPopup && dnsData && (
        <DnsPopup data={dnsData} agencyData={agency} onClose={handleDnsPopupClose} />
      )}
    </div>
  );
};

const DnsPopup = ({ data, agencyData, onClose }) => {
  const [StoreDnsStatus, setStoreDnsStatus] = useState(false);
  const [CertDnsStatus, setCertDnsStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert('Copied to clipboard!'))
      .catch(err => console.error('Failed to copy text: ', err));
  };

  const handleDnsValidation = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await apiService.get("/api/certificates/CheckDNSPropagationStatus/" + agencyData.ID)
        .then((res) => {
          setStoreDnsStatus(res.data.isStoreDnsPropagated);
          setCertDnsStatus(res.data.isTLSDnsPropagated);
        });

    } catch (err) {
      console.error("Failed to validate DNS", err);
      toast.error("Failed to validate DNS");
    } finally {
      setLoading(false);
    }
  }
  const handleCreateStoreCertificate = (e) => {
    e.preventDefault();
    try {
      // let changeAgecyStatus = false;
      // const agencyUpdate = axios.put("/api/agency/" + agencyData.ID, { fkAssignedStore: agencyData.fkAssignedStore }, {
        
      // })

      const token = localStorage.getItem("token");
      const response = apiService.post("/api/certificates/UpdateStoreCertificate", { agencyID: agencyData.ID, storeID: agencyData.fkAssignedStore }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': "application/json"
        },
      })
        .then((res) => {
          if (res.data == true) {
            toast.success("Store certificate created successfully");
            // setShowDnsPopup(false);
            onClose();
          } else {
            toast.error("Failed to create store certificate. Contact your AWS administrator");
          }
        }).catch((err) => {
          console.error("Failed to create store certificate", err);
          toast.error("Failed to create store certificate. Contact your AWS administrator" + err, { autoClose: 3000 });
        });
    } catch (err) {
      console.error("Failed to create store certificate", err);
      toast.error("Failed to create store certificate. Contact your AWS administrator" + err);
    }
  }

  // console.log(data);
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
      {loading && <Loader/>}
      <div className="bg-white p-8 rounded shadow-lg relative max-w-4xl w-full max-h-96 overflow-auto">
        {/* <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-900 focus:outline-none"
        > */}
          {/* <FaCross className="h-4 w-4" /> */}
          {/* <img src={closeIcon} alt="Close" className="h-4 w-4" />
          
        </button> */}
        <XCircleIcon
          onClick={onClose}
          className="h-6 w-6 text-red-500 hover:text-red-700 absolute top-2 right-2 cursor-pointer"
        />
        <h2 className="text-xl font-bold mb-4">DNS Details</h2>
        <div className="mb-4">
          <table className="w-full border-collapse table-fixed text-sm">
            <thead>
              <tr>
                <th className="border px-4 py-2 w-1/4" style={{ width: "10%" }}>Type</th>
                <th className="border px-4 py-2 w-1/4">Hostname</th>
                <th className="border px-4 py-2 w-1/4">Value</th>
                <th className="border px-4 py-2 w-1/4" style={{ width: "15%" }}>Status</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td className="border px-4 py-2 w-1/4">{/*{record.type}*/}CNAME</td>
                <td className="border px-4 py-2 break-all">

                  <div className="flex flex-row">
                    <div className="flex-3/4 w-11/12">
                      {data.certDNSName}

                    </div>
                    <div className="flex-1/4 text-right hover:cursor-pointer hover:text-blue-500">
                      <FaCopy size={16} onClick={() => copyToClipboard(data.certDNSName)} />

                    </div>

                  </div>


                </td>
                <td className="border px-4 py-2 break-all">

                  <div className="flex flex-row">
                    <div className="flex-3/4 w-11/12">
                      {data.certDNSValue}

                    </div>
                    <div className="flex-1/4 text-right hover:cursor-pointer hover:text-blue-500">
                      <FaCopy size={16} onClick={() => copyToClipboard(data.certDNSValue)} />

                    </div>

                  </div>


                </td>
                <td className="border px-4 py-2 text-center">
                  <div className="`border px-4 py-2 ${CertDnsStatus ? 'text-white' : 'text-black'} font-bold p-1 rounded`"
                    style={{ backgroundColor: CertDnsStatus ? "green" : "yellow" }}
                  >
                    <span className="`${StoreDnsStatus ? 'text-white' : 'text-black'} `">
                      {CertDnsStatus ? "Validation Success" : "Validation Pending"}
                    </span>

                  </div>

                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">{/*{record.type}*/}CNAME</td>
                <td className="border px-4 py-2">
                  {/* {data.agencyName} */}
                  <div className="flex flex-row">
                    <div className="flex-3/4 w-11/12">
                      {data.agencyName}

                    </div>

                    <div className="text-right hover:cursor-pointer hover:text-blue-500">
                      <FaCopy size={16} onClick={() => copyToClipboard(data.agencyName)} />

                    </div>

                  </div>


                </td>
                <td className="border px-4 py-2">


                  <div className="flex flex-row">
                    <div className="flex-3/4 w-11/12">
                      {data.storeName}

                    </div>
                    <div className="flex-1/4 text-right hover:cursor-pointer hover:text-blue-500">
                      <FaCopy size={16} onClick={() => copyToClipboard(data.storeName)} />

                    </div>

                  </div>
                </td>
                <td className="border px-4 py-2 text-center">
                  <div className="` border px-4 py-2 ${StoreDnsStatus ? 'text-white' : 'text-black'} font-bold p-1 rounded `"
                    style={{ backgroundColor: StoreDnsStatus ? "green" : "yellow" }}
                  >
                    <span className="`${StoreDnsStatus ? 'text-white' : 'text-black'} `">
                      {StoreDnsStatus ? "Validation Success" : "Validation Pending"}
                    </span>
                  </div>
                </td>
              </tr>
              {/* </React.Fragment>
              // {))} } */}
            </tbody>
          </table>
        </div>
        {/* <div className="flex flex-row w-full flex-wrap">
          <div className="flex-1/3 w-1/3">
            hi
          </div>
          <div className="flex-1/3 w-1/3">
            hiii
          </div>
          <div className="flex-1/3 w-1/3">
            hiii
          </div>

        </div> */}
        <div className="flex justify-end space-x-4 mt-4">
          <button
            onClick={handleDnsValidation}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
          >
            Validate DNS
          </button>
          <button
            onClick={handleCreateStoreCertificate}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none"
            disabled={!(CertDnsStatus && StoreDnsStatus)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

// const StoreCertificateStatusModal = ({ onClose, data }) => {

//   return (

//     <div className="fixed inset-0 flex items-center justify-center z-50">

//     </div>


//   );

// };

export default AddCertificateModal;
