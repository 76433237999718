// import React, { useState, useEffect, useContext } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import "tailwindcss/tailwind.css";
// import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
// import ConfirmationModal from "./ConfirmationModal";
// import AddCertificateModal from "../certificates/AddCertificateModal";
// import Modal from "../../Modal1";
// import apiService from "../../services/apiService";
// import agencyService from "../../services/agencyService";
// import { AgencyStatusContext } from "../../contexts/agencyStatusContext";
// import Loader from "../loader/Loader";
// import {jwtDecode} from 'jwt-decode';
// import Pagination from "../pagination/Pagination";

// const AgencyList = ({ onUpdateAgency }) => {
//   const [agencies, setAgencies] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isLoaderVisible, setIsLoaderVisible] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
//   const [isAddCertificateModalOpen, setIsAddCertificateModalOpen] =
//     useState(false);
//   const [isAgencyCredentialsModalOpen, setIsAgencyCredentialsModalOpen] =
//     useState(false);
//   const [selectedAgency, setSelectedAgency] = useState(null);
//   const [isAdmin, setIsAdmin] = useState(false);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [totalRecords, setTotalRecords] = useState(0);
//   const [limit, setLimit] = useState(10);

//   const token = localStorage.getItem("token");
//   const { agencyStatusResponse, setAgencyStatusResponse } =
//     useContext(AgencyStatusContext);

//   useEffect(() => {
//     const initialize = async () => {
//       if (token) {
//         try {
//           const decodedToken = jwtDecode(token);
//           const username = decodedToken?.username;
//           setIsAdmin(username === "admin");
//         } catch (error) {
//           console.error("Failed to decode token", error);
//         }
//       }
//       await fetchAgencies1(page);
//     };

//     initialize();
//   }, [token, page, limit]);

//   const fetchAgencies1 = async (page,limit) => {
//     setLoading(true);
//     // setIsLoaderVisible(true); // Show loader when fetching agencies
//     try {
//       const response = await apiService.get(`/api/agencies/?page=${page}&limit=${limit}`);
//       const { Items, TotalPages, TotalCount } = response.data; // Adjust according to your API response structure
//       setAgencies(Items);
//       setTotalPages(TotalPages); // Update total pages from response
//       setTotalRecords(TotalCount); // Set total records from response
//     } catch (error) {
//       setError("Failed to fetch agencies");
//     } finally {
//       setLoading(false);
//       // setIsLoaderVisible(false); // Hide loader after fetch
//     }
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage < 1 || newPage > totalPages) return; // Avoid invalid page numbers
//     setPage(newPage);
//   };

//   const handleOpenModal = (agency) => {
//     setSelectedAgency(agency);
//     setIsModalOpen(true);
//   };

//   const handleAddAgency = () => {
//     setSelectedAgency(null);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setSelectedAgency(null);
//   };

//   const handleOpenConfirmationModal = (agency) => {
//     setSelectedAgency(agency);
//     setIsConfirmationModalOpen(true);
//   };

//   const handleCloseConfirmationModal = () => {
//     setIsConfirmationModalOpen(false);
//     setSelectedAgency(null);
//   };

//   const handleDeleteAgency = async () => {
//     setIsLoaderVisible(true);
//     try {
//       const response = await apiService.delete(
//         `/api/agencies/${selectedAgency.ID}`
//       );
//       if (response.status === 200) {
//         toast.success("Agency deleted successfully!");
//         fetchAgencies1(page);
//       }
//     } catch (error) {
//       console.error("Failed to delete agency", error);
//     } finally {
//       setIsLoaderVisible(false);
//       handleCloseConfirmationModal();
//     }
//   };

//   const handleOpenAddCertificateModal = (agency) => {
//     if (agency) {
//       if (agency.fkAgencyStatus > 2) {
//         apiService
//           .post(`/api/certificates/AddDomainInCloudfront`, {
//             agencyID: agency.ID,
//             storeID: agency.fkAssignedStore,
//           })
//           .then((response) => {
//             toast.success("Store Added Successfully");
//              fetchAgencies1(page);
//           })
//           .catch((error) => {
//             console.error(
//               "Failed to add store! Please contact your AWS Administrator",
//               error
//             );
//             toast.error(
//               "Failed to add store! Please contact your AWS Administrator"
//             );
//           });
//       } else {
//         setSelectedAgency(agency);
//         setIsAddCertificateModalOpen(true);
//       }
//     } else {
//       console.error("No agency selected");
//     }
//   };

//   const renderCertButton = (status) => {
//     switch (status) {
//       case 1:
//         return "Request Certificate";
//       case 2:
//         return "View Certificate Request";
//       case 3:
//         return "Add To CloudFront";
//       case 4:
//         return "Activated";
//       default:
//         return "Request Certificate";
//     }
//   };

//   const handleCloseAddCertificateModal = () => {
//     setIsAddCertificateModalOpen(false);
//     setSelectedAgency(null);
//   };

//   const handleAddAgencyOpenModal = () => {
//     setIsAgencyCredentialsModalOpen(true);
//   };

//   const handleAddAgencyCloseModal = () => {
//     setIsAgencyCredentialsModalOpen(false);
//   };

//   const handleLimitChange = (event) => {
//     setLimit(parseInt(event.target.value, 10));
//     setPage(1);
//   };

//   // if (loading) return <p>Loading...</p>;

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="overflow-x-auto">
//       {/* {loading && <Loader />} */}
//       <div className="flex flex-col md:flex-row justify-between items-center mb-4">
//         <div className="flex-1">
//           <h2 className="text-2xl font-bold" style={{ color: "#225282" }}>
//             Agency List
//           </h2>
//         </div>
//       </div>
//       <div className="mb-4">
//         <label htmlFor="limit" className="mr-2">
//           Items per page:
//         </label>
//         <select
//           id="limit"
//           value={limit}
//           onChange={handleLimitChange}
//           className="px-2 py-1 border rounded"
//         >
//           <option value={10}>10</option>
//           <option value={25}>25</option>
//           <option value={50}>50</option>
//           <option value={100}>100</option>
//         </select>
//       </div>
//       <table className="min-w-full divide-y divide-gray-200">
//         <thead className="bg-gray-50">
//           <tr>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//               Agency Name
//             </th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//               Domain Name
//             </th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//               Assigned Store
//             </th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//               Agency Status
//             </th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//               Active
//             </th>
//             {isAdmin && (
//               <>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   Update
//                 </th>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                   Delete
//                 </th>
//               </>
//             )}
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//               Request Certificate
//             </th>
//           </tr>
//         </thead>
//         <tbody className="bg-white divide-y divide-gray-200">
//           {agencies.map((agency) => (
//             <tr key={agency.ID}>
//               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                 {agency.agencyName}
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                 {agency.agencyDomainName}
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                 {agency.store.storeName}
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                 {agency.agencyStatus.statusName}
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                 {agency.isActive ? "Yes" : "No"}
//               </td>
//               {isAdmin && (
//                 <>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
//                     <PencilAltIcon
//                       className="h-5 w-5 text-blue-500 cursor-pointer"
//                       onClick={() => handleOpenModal(agency)}
//                     />
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
//                     <TrashIcon
//                       className="h-5 w-5 text-red-500 cursor-pointer"
//                       onClick={() => handleOpenConfirmationModal(agency)}
//                     />
//                   </td>
//                 </>
//               )}
//               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                 <button
//                   disabled={agency.fkAgencyStatus === 4}
//                   onClick={() => handleOpenAddCertificateModal(agency)}
//                   className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
//                 >
//                   {renderCertButton(agency.fkAgencyStatus)}
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <Pagination
//         currentPage={page}
//         totalPages={totalPages}
//         totalRecords={totalRecords}
//         limit={limit}
//         onPageChange={handlePageChange}
//       />
//       {isModalOpen && (
//         <Modal
//           isOpen={isModalOpen}
//           onClose={handleCloseModal}
//           agency={selectedAgency}
//           onUpdateAgency={onUpdateAgency}
//         />
//       )}
//       {isAddCertificateModalOpen && (
//         <AddCertificateModal
//           isOpen={isAddCertificateModalOpen}
//           onClose={handleCloseAddCertificateModal}
//           agency={selectedAgency}
//         />
//       )}
//       {isConfirmationModalOpen && (
//         <ConfirmationModal
//           isOpen={isConfirmationModalOpen}
//           onClose={handleCloseConfirmationModal}
//           onConfirm={handleDeleteAgency}
//         />
//       )}
//       {isAgencyCredentialsModalOpen && (
//         <Modal
//           isOpen={isAgencyCredentialsModalOpen}
//           onClose={handleAddAgencyCloseModal}
//           agency={null}
//           onAddAgency={handleAddAgency}
//         />
//       )}
//       <ToastContainer />
//     </div>
//   );
// };

// export default AgencyList;







import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "tailwindcss/tailwind.css";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import ConfirmationModal from "./ConfirmationModal";
import AddCertificateModal from "../certificates/AddCertificateModal";
import Modal from "../../Modal1";
import apiService from "../../services/apiService";
import agencyService from "../../services/agencyService";
import { AgencyStatusContext } from "../../contexts/agencyStatusContext";
import Loader from "../loader/Loader";
import {jwtDecode} from 'jwt-decode';
import Pagination from "../pagination/Pagination";

const AgencyList = ({ onUpdateAgency }) => {
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAddCertificateModalOpen, setIsAddCertificateModalOpen] =
    useState(false);
  const [isAgencyCredentialsModalOpen, setIsAgencyCredentialsModalOpen] =
    useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(10);

  const token = localStorage.getItem("token");
  const { agencyStatusResponse, setAgencyStatusResponse } =
    useContext(AgencyStatusContext);

  useEffect(() => {
    const initialize = async () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const username = decodedToken?.username;
          setIsAdmin(username === "admin");
        } catch (error) {
          console.error("Failed to decode token", error);
        }
      }
      await fetchAgencies1(page,limit);
    };

    initialize();
  }, [token, page, limit]);

  const fetchAgencies1 = async (page,limit) => {
    console.log("---> pages and limit", page, limit);
    setLoading(true);
    // setIsLoaderVisible(true); // Show loader when fetching agencies
    try {
      const response = await apiService.get(`/api/agencies/?page=${page}&limit=${limit}`);
      const { Items, TotalPages, TotalCount } = response.data; // Adjust according to your API response structure
      setAgencies(Items);
      setTotalPages(TotalPages); // Update total pages from response
      setTotalRecords(TotalCount); // Set total records from response
    } catch (error) {
      setError("Failed to fetch agencies");
    } finally {
      setLoading(false);
      // setIsLoaderVisible(false); // Hide loader after fetch
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return; // Avoid invalid page numbers
    setPage(newPage);
  };

  const handleOpenModal = (agency) => {
    setSelectedAgency(agency);
    setIsModalOpen(true);
  };

  const handleAddAgency = () => {
    setSelectedAgency(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAgency(null);
  };

  const handleOpenConfirmationModal = (agency) => {
    setSelectedAgency(agency);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setSelectedAgency(null);
  };

  const handleDeleteAgency = async () => {
    setIsLoaderVisible(true);
    try {
      const response = await apiService.delete(
        `/api/agencies/${selectedAgency.ID}`
      );
      if (response.status === 200) {
        toast.success("Agency deleted successfully!");
        fetchAgencies1(page);
      }
    } catch (error) {
      console.error("Failed to delete agency", error);
    } finally {
      setIsLoaderVisible(false);
      handleCloseConfirmationModal();
    }
  };

  const handleOpenAddCertificateModal = (agency) => {
    if (agency) {
      if (agency.fkAgencyStatus > 2) {
        apiService
          .post(`/api/certificates/AddDomainInCloudfront`, {
            agencyID: agency.ID,
            storeID: agency.fkAssignedStore,
          })
          .then((response) => {
            toast.success("Store Added Successfully");
             fetchAgencies1(page);
          })
          .catch((error) => {
            console.error(
              "Failed to add store! Please contact your AWS Administrator",
              error
            );
            toast.error(
              "Failed to add store! Please contact your AWS Administrator"
            );
          });
      } else {
        setSelectedAgency(agency);
        setIsAddCertificateModalOpen(true);
      }
    } else {
      console.error("No agency selected");
    }
  };

  const renderCertButton = (status) => {
    switch (status) {
      case 1:
        return "Request Certificate";
      case 2:
        return "View Certificate Request";
      case 3:
        return "Add To CloudFront";
      case 4:
        return "Activated";
      default:
        return "Request Certificate";
    }
  };

  const handleCloseAddCertificateModal = () => {
    setIsAddCertificateModalOpen(false);
    setSelectedAgency(null);
  };

  const handleAddAgencyOpenModal = () => {
    setIsAgencyCredentialsModalOpen(true);
  };

  const handleAddAgencyCloseModal = () => {
    setIsAgencyCredentialsModalOpen(false);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(parseInt(newLimit));
    setPage(1);
  };

  // if (loading) return <p>Loading...</p>;

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
      {/* {loading && <Loader />} */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <div className="flex-1">
          <h2 className="text-2xl font-bold" style={{ color: "#225282" }}>
            Agency List
          </h2>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Agency Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Domain Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Assigned Store
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Agency Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Active
            </th>
            {isAdmin && (
              <>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Update
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Delete
                </th>
              </>
            )}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Request Certificate
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {agencies.map((agency) => (
            <tr key={agency.ID}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.agencyName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.agencyDomainName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.store.storeName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.agencyStatus.statusName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {agency.isActive ? "Yes" : "No"}
              </td>
              {isAdmin && (
                <>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <PencilAltIcon
                      className="h-5 w-5 text-blue-500 cursor-pointer"
                      onClick={() => handleOpenModal(agency)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <TrashIcon
                      className="h-5 w-5 text-red-500 cursor-pointer"
                      onClick={() => handleOpenConfirmationModal(agency)}
                    />
                  </td>
                </>
              )}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                  disabled={agency.fkAgencyStatus === 4}
                  onClick={() => handleOpenAddCertificateModal(agency)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                >
                  {renderCertButton(agency.fkAgencyStatus)}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        currentPage={page}
        totalPages={totalPages}
        totalRecords={totalRecords}
        limit={limit}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          agency={selectedAgency}
          onUpdateAgency={onUpdateAgency}
        />
      )}
      {isAddCertificateModalOpen && (
        <AddCertificateModal
          isOpen={isAddCertificateModalOpen}
          onClose={handleCloseAddCertificateModal}
          agency={selectedAgency}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={handleDeleteAgency}
        />
      )}
      {isAgencyCredentialsModalOpen && (
        <Modal
          isOpen={isAgencyCredentialsModalOpen}
          onClose={handleAddAgencyCloseModal}
          agency={null}
          onAddAgency={handleAddAgency}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default AgencyList;