import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import { PencilAltIcon } from "@heroicons/react/solid";
import UpdateStore from "./UpdateStore";
import apiService from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateStore from "./CreateStore";
import {jwtDecode} from "jwt-decode";
import Pagination from "../pagination/Pagination";

const StoreComponent = () => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [isStoreCredentialsModalOpen, setIsStoreCredentialsModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1); // Add totalPages state

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAdmin(decodedToken?.username === "admin");
      } catch (error) {
        console.error("Failed to decode token", error);
      }
    }

    const fetchStores = async () => {
      try {
        const res = await apiService.get("/api/store-status");
        const response = await apiService.get(`/api/stores?page=${currentPage}&limit=${limit}`);
        console.log("---> get the store result :", response);

        const responseWithStatus = response.data.Items.map((store) => ({
          ...store,
          storeStatusName: res.data.find((status) => status.id === store.fkStoreStatus)?.statusName || "Unknown",
        }));

        setStores(responseWithStatus);
        setTotalRecords(response.data.TotalCount);
        setTotalPages(response.data.TotalPages);
      } catch (error) {
        console.error("Failed to fetch stores", error);
        setError("Failed to fetch stores");
        toast.error("Failed to fetch stores");
      } finally {
        setLoading(false);
      }
    };

    fetchStores();
  }, [currentPage, limit]);

  const handleStoreOpenModal = (store) => {
    setSelectedStore(store);
    setIsModalOpen(true);
  };

  const handleStoreCloseModal = () => {
    setIsModalOpen(false);
    setSelectedStore(null);
  };

  const handleAddStoreOpenModal = () => {
    setIsStoreCredentialsModalOpen(true);
  };

  const handleAddStoreCloseModal = () => {
    setIsStoreCredentialsModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1); // Reset to the first page when limit changes
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="bg-white p-6 rounded shadow-md relative">
      <ToastContainer />
      <div className="flex justify-between">
        <div className="mb-4 flex-3/4">
          <h2 className="text-xl font-bold mb-4" style={{ color: "#225282" }}>Stores</h2>
        </div>
        {isAdmin && (
          <div className="mb-4 flex-1/4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleAddStoreOpenModal}
            >
              Add Store
            </button>
          </div>
        )}
      </div>
      <div className="overflow-x-auto text-sm">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-left">S.No</th>
              <th className="py-2 px-4 border-b text-left">Store Name</th>
              <th className="py-2 px-4 border-b text-left">Current ACM ID</th>
              <th className="py-2 px-4 border-b text-left">Cloudfront ID</th>
              <th className="py-2 px-4 border-b text-left">Store Status</th>
              {isAdmin && (<th className="py-2 px-4 border-b text-left">Update</th>)}
            </tr>
          </thead>
          <tbody>
            {stores.map((store, index) => (
              <tr key={store.ID}>
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">{store.storeName}</td>
                <td className="py-2 px-4 border-b">{store.currentAcmID}</td>
                <td className="py-2 px-4 border-b">{store.storeCfID}</td>
                <td className="py-2 px-4 border-b">{store.storeStatusName}</td>
                {isAdmin && (
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      onClick={() => handleStoreOpenModal(store)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <PencilAltIcon className="h-5 w-5 inline" />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
          limit={limit}
        />
      </div>
      {isModalOpen && (
        <UpdateStore onClose={handleStoreCloseModal} store={selectedStore} />
      )}
      {isStoreCredentialsModalOpen && (
        <CreateStore onClose={handleAddStoreCloseModal} />
      )}
    </div>
  );
};

export default StoreComponent;
